import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import colours from "../../styles/colours";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  stageProgress: {
    width: "9.375rem",
    height: ".75rem",
    borderRadius: 6,
    backgroundColor: "#ededed",
    overflow: "hidden",
  },
  stageProgressPercentageDone: {
    // TODO: This doesn't work, maybe because React replaces the item or something? Works when changed manually in browser
    transition: generateTransition({ targets: ["width"], speed: "500ms" }),
    backgroundColor: colours.selected,
    height: "100%",
  },
};

const StageProgress = (props) => {
  const { stageIndex, stages } = props;
  const { styles } = useStyles(baseStyles, props);

  const doneStyle = useMemo(
    () => ({
      width: `${(100 / (stages.length - 1)) * stageIndex}%`,
    }),
    [stages, stageIndex]
  );

  return (
    <div className={css(styles.stageProgress)}>
      <div
        className={css(styles.stageProgressPercentageDone)}
        style={doneStyle}
      />
    </div>
  );
};

StageProgress.propTypes = {
  stageIndex: PropTypes.number.isRequired,
  stages: PropTypes.array.isRequired,
};

export default memo(StageProgress);
