import { css } from "aphrodite";
import PropTypes from "prop-types";
import qs from "qs";
import { memo, useCallback } from "react";

import UserCard from "components/Entities/Cards/UserCards/UserCardContainerAsync";
import FollowEntityStageSuggestions from "components/Entities/Page/FollowEntityStageSuggestions";
import FollowStageBase from "components/Entities/Page/FollowStageBase";

import StageSelect from "./StageSelect";

import * as sortConstants from "constants/sort";
import loadUsersList from "sagas/pagination/lists/loadUsersList";

import useQuery from "hooks/useQuery";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const TITLE = "Follow Users";
const MOBILE_TITLE = "Follow Other Users";

const LIST_KEY = "onboarding/follow/users";

const LIST_CONFIG = {
  sort: sortConstants.SORT_ORDER_POPULAR,
  sort_direction: "desc",
  list_type: "users_filtered",
  loadListAction: loadUsersList,
  entity_type: "user",
  staticFilters: {
    profile_image: {
      value: "has_image",
    },
  },
  pageSize: 20,
};

const userStyles = {
  personFollowCard: {
    width: "100%",
    borderBottom: "#eef0f2 1px solid",

    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      borderBottom: "none",
    },
  },
  buttonContainer: {
    marginLeft: "auto",

    [ScreenSizes.lgAndAbove]: {
      marginLeft: 0,
    },
  },
};

const infiniteListStyles = {
  sensorItem: {
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      width: "calc(50% - .75rem)",
    },
  },
};

const stageStyles = {
  suggestionsContainer: {
    justifyContent: "space-between",
    maxWidth: "40rem",
    margin: "1.25rem auto 0",
  },
};

const baseStageStyles = {
  followControls: {
    [ScreenSizes.mdAndAbove]: {
      position: "relative",
      margin: "0 auto 2.5rem",
    },
  },
  importTitle: {
    display: "none",
  },
};

const baseStyles = {
  followStageBase: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1.25rem 1rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 1.75rem 1rem",
    },
  },
  followStageDescription: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    lineHeight: "24px",
    letterSpacing: "0.04px",
    maxWidth: "41.5rem",
    width: "100%",
    padding: 0,
    margin: "0 auto 1.625rem",
    textAlign: "center",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 2rem",
      margin: "0 auto 1.5rem",
    },
  },
};

const DESCRIPTION =
  "Follow your friends and other users to see podcast reviews, new Lists, and other user activity in your Podchaser Feed!";

const FollowUserStage = (props) => {
  const {
    mobile,
    stage,
    setSocialConnect,
    socialConnect,
    showFollowStages,
    onlyShowOneStep,
    nextStage,
    previousStage,
    stageIndex,
  } = props;

  const renderItem = useCallback(
    (user) => (
      <UserCard
        key={user}
        entity_id={user}
        layout="follow"
        styles={userStyles}
        externalLink
        disablePopup
      />
    ),
    []
  );

  const title = mobile ? MOBILE_TITLE : TITLE;
  const suggestionsTitle = mobile
    ? "Some popular users to follow"
    : "Here are some popular users to get you started.";

  const { styles } = useStyles(baseStyles, { styles: { stageStyles } });

  const [query] = useQuery();

  const renderSuggestionsComponent = useCallback(
    (passedProps) => {
      const {
        isConnected,
        socialConnectFromTab,
        listKey,
        defaultTab,
        currentTabKey,
        connecting,
      } = passedProps;

      return (
        <FollowEntityStageSuggestions
          socialConnect={socialConnect}
          suggestionsTitle={suggestionsTitle}
          entity_type={"user"}
          isConnected={isConnected}
          socialConnectFromTab={socialConnectFromTab}
          suggestionsListConfig={LIST_CONFIG}
          defaultTab={defaultTab}
          listKey={listKey}
          currentTabKey={currentTabKey}
          renderSuggestionItem={renderItem}
          infiniteListStyles={infiniteListStyles}
          connecting={connecting}
        />
      );
    },
    [renderItem, socialConnect, suggestionsTitle]
  );

  return (
    <div className={css(styles.followStageBase)}>
      <StageSelect
        title={title}
        showFollowStages={showFollowStages}
        onlyShowOneStep={onlyShowOneStep}
        mobile={mobile}
        nextStage={nextStage}
        previousStage={previousStage}
        stageIndex={stageIndex}
      />
      <div className={css(styles.followStageDescription)}>{DESCRIPTION}</div>
      <FollowStageBase
        listKey={LIST_KEY}
        allowOPML={false}
        hideSocialImports={false}
        entity_type={"user"}
        setSocialConnect={setSocialConnect}
        socialConnect={socialConnect}
        placeholder={"Search by name or username..."}
        mobile={mobile}
        styles={baseStageStyles}
        getConnectReturnUrl={(socialType) =>
          `${window.location.pathname}?${qs.stringify({
            ...query,
            modal: "onboarding",
            modalprop_social_connect: socialType,
            modalprop_onboardingStep: stage,
            modalprop_showStepNavigation: true,
          })}`
        }
        renderSuggestionsComponent={renderSuggestionsComponent}
      />
    </div>
  );
};

FollowUserStage.propTypes = {
  mobile: PropTypes.bool,
  stage: PropTypes.string.isRequired,
  socialConnect: PropTypes.string,
  setSocialConnect: PropTypes.func.isRequired,
  onlyShowOneStep: PropTypes.bool,
  showFollowStages: PropTypes.bool,
  nextStage: PropTypes.func.isRequired,
  previousStage: PropTypes.func.isRequired,
  stageIndex: PropTypes.number.isRequired,
};

FollowUserStage.defaultProps = {
  mobile: false,
  onlyShowOneStep: false,
  showFollowStages: false,
};

export default memo(FollowUserStage);
