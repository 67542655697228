import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  stageSelect: {
    display: "flex",
    flexDirection: "column",
  },
  navigationArrows: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  spaceBetweenArrows: {
    justifyContent: "space-between",
  },
  stageName: {
    ...gStyles.fontSemiBold,
    color: colours.black,
    textAlign: "center",
  },
  arrow: {
    fontSize: ".75rem",
    color: "var(--color-neutral-d6)",
    padding: ".2rem 1.5rem",
    cursor: "pointer",
  },
  mobileArrow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.25rem",
    padding: 0,
    width: "1.5rem",
    height: "1.5rem",
  },
  stageSelectMobileTitleContainer: {
    width: "100%",
    margin: ".5rem 0 1.5rem",
  },
  stageSelectMobileTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.75rem",
    textAlign: "center",
  },
  stageSelectTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.75rem",
    width: "100%",
    textAlign: "center",
    margin: "1.125rem 0 1.875rem",
  },
};

const StageSelect = (props) => {
  const {
    nextStage,
    previousStage,
    stageIndex,
    title,
    mobile,
    onlyShowOneStep,
    showFollowStages,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const renderNavigationArrows = (content, spaceBetweenArrows = false) => (
    <div
      className={css(
        styles.navigationArrows,
        spaceBetweenArrows && styles.spaceBetweenArrows
      )}
    >
      <div
        className={css(styles.arrow, mobile && styles.mobileArrow)}
        onClick={previousStage}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>
      {content}
      <div
        className={css(styles.arrow, mobile && styles.mobileArrow)}
        onClick={nextStage}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    </div>
  );

  if (mobile && title) {
    return (
      <div className={css(styles.stageSelect)}>
        {!onlyShowOneStep && !showFollowStages && (
          <div className={css(styles.stageName)}>{`Step ${stageIndex}`}</div>
        )}
        {!onlyShowOneStep && !showFollowStages && title && (
          <div className={css(styles.stageSelectMobileTitleContainer)}>
            {renderNavigationArrows(
              <div className={css(styles.stageSelectMobileTitle)}>{title}</div>,
              true
            )}
          </div>
        )}
        {(onlyShowOneStep || showFollowStages) && title && (
          <div className={css(styles.stageSelectMobileTitle)}>{title}</div>
        )}
      </div>
    );
  }

  return (
    <div className={css(styles.stageSelect)}>
      {!onlyShowOneStep &&
        !showFollowStages &&
        renderNavigationArrows(
          <div className={css(styles.stageName)}>{`Step ${stageIndex}`}</div>
        )}
      {title && <div className={css(styles.stageSelectTitle)}>{title}</div>}
    </div>
  );
};

StageSelect.propTypes = {
  nextStage: PropTypes.func.isRequired,
  previousStage: PropTypes.func.isRequired,
  stageIndex: PropTypes.number.isRequired,
  title: PropTypes.node,
  mobile: PropTypes.bool,
  onlyShowOneStep: PropTypes.bool,
  showFollowStages: PropTypes.bool,
};

StageSelect.defaultProps = {
  title: null,
  mobile: false,
  onlyShowOneStep: false,
  showFollowStages: false,
};

export default memo(StageSelect);
