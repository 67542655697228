import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const OnResize = Loadable(() => import("./OnResize"));

function OnResizesyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const OnResizeContainer = (props) => {
  return <OnResize {...props} fallback={<OnResizesyncLoading />} />;
};

export default OnResizeContainer;
