import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import CreatorCard from "components/Entities/Cards/CreatorCards/CreatorCardContainerAsync";

import FollowStageBase from "./FollowStageBase";

import * as sortConstants from "constants/sort";
import loadCreatorsList from "sagas/pagination/lists/loadCreatorsList";

import useLoggedInUser from "hooks/useLoggedInUser";

import ScreenSizes from "styles/ScreenSizes";

const DESCRIPTION =
  "When you follow podcast Creators, your Podchaser Feed will show you recent " +
  "guest credits and other contributions from the Creators you follow.";
const MOBILE_DESCRIPTION =
  "When you follow podcast Creators, you'll see recent guest credits " +
  "and other contributions from the Creators you follow.";

const LIST_KEY = "onboarding/follow/creators";

const LIST_CONFIG = {
  sort: sortConstants.SORT_ORDER_RELEVANCE,
  list_type: "creators_filtered",
  loadListAction: loadCreatorsList,
  entity_type: "creator",
  pageSize: 20,
  filters: {
    profile_image: {
      value: "has_image",
    },
  },
  options: {
    sort_context: "followed_podcasts",
  },
};

const creatorStyles = {
  personFollowCard: {
    width: "100%",
    borderBottom: "#eef0f2 1px solid",

    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      borderBottom: "none",
    },
  },
  buttonContainer: {
    marginLeft: "auto",

    [ScreenSizes.lgAndAbove]: {
      marginLeft: 0,
    },
  },
};

const infiniteListStyles = {
  sensorItem: {
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      width: "calc(50% - .75rem)",
    },
  },
};

const stageStyles = {
  suggestionsContainer: {
    justifyContent: "space-between",
    maxWidth: "40rem",
    margin: "1.25rem auto 0",
  },
};

const FollowCreatorStage = (props) => {
  const { mobile, onlyShowOneStep } = props;
  const loggedInUser = useLoggedInUser();

  const renderItem = useCallback(
    (creator) => (
      <CreatorCard
        key={creator}
        entity_id={creator}
        layout="follow"
        styles={creatorStyles}
        externalLink
        disablePopup
      />
    ),
    []
  );

  const suggestionsTitle = useMemo(() => {
    if (loggedInUser.get("podcast_follow_count") > 0) {
      return mobile
        ? "Some creators to follow based on your followed podcasts"
        : "Here are suggested creators to follow based on your followed podcasts.";
    }

    return mobile
      ? "Some suggested creators to follow"
      : "Here are some popular Creators you might like to follow.";
  }, [mobile, loggedInUser]);

  return (
    <FollowStageBase
      {...props}
      title="Follow Creators"
      description={mobile ? MOBILE_DESCRIPTION : DESCRIPTION}
      listKey={LIST_KEY}
      suggestionsTitle={!onlyShowOneStep ? suggestionsTitle : undefined}
      searchPlaceholder="Search for a creator by name..."
      suggestionsListKey={LIST_KEY}
      suggestionsListConfig={LIST_CONFIG}
      renderSuggestionItem={renderItem}
      requiredSuggestionsCount={3}
      followCount={loggedInUser.get("creator_follow_count")}
      styles={stageStyles}
      infiniteListStyles={infiniteListStyles}
      entity_type="creator"
    />
  );
};

FollowCreatorStage.propTypes = {
  mobile: PropTypes.bool,
  onlyShowOneStep: PropTypes.bool,
};

FollowCreatorStage.defaultProps = {
  mobile: false,
  onlyShowOneStep: false,
};

export default memo(FollowCreatorStage);
