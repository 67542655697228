import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateProfile as updateProfileRoutine } from "routines/profile";

import ActivityContext from "components/Analytics/ActivityContext";
import BaseModal from "components/Common/Modals/BaseModal";
import OnResize from "components/Common/OnResizeAsync";

import PodchaserBrandLogo from "../Branding/PodchaserBrandLogo";
import ModalCloseButton from "../Common/Modals/ModalCloseButton";
import FinishedStage from "./FinishedStage";
import FollowCreatorStage from "./FollowCreatorStage";
import FollowNavigationButton from "./FollowNavigationButton";
import FollowPodcastStage from "./FollowPodcastStage";
import FollowUserStage from "./FollowUserStage";
import LandingStage from "./LandingStage";
import StageProgress from "./StageProgress";

import accessibleClickProps from "utils/misc/accessibleClickProps";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLayoutEffect from "hooks/useLayoutEffect";
import { useLocalStorage } from "usehooks-ts";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useTimelineAction from "hooks/useTimelineAction";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const FOOTER_HEIGHT = 76;

const baseStyles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  modalTopFiller: {
    ...gStyles.gradientBackground,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "4.25rem",
    minHeight: "4.25rem",
  },
  modalTopFillerIcon: {
    width: "2.625rem",
    height: "2.625rem",
  },
  modalContent: {
    backgroundColor: colours.white,
    flex: 1,
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",

    [ScreenSizes.lgAndAbove]: {
      marginTop: 0,
    },
  },
  mobileModalContent: {
    maxHeight: "calc(100% - 4.25rem)", // takes into account for the modalTopFiller
  },
  modalTitle: {
    ...gStyles.fontSemiBold,
    color: colours.black,
    fontSize: ".94rem",
    textAlign: "center",
  },
  modalClose: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0,
    height: "4.25rem",
    width: "3rem",
    fontSize: "1.2rem",
    color: colours.white,
    cursor: "pointer",
    opacity: ".75",

    [ScreenSizes.lgAndAbove]: {
      color: colours.oldSecondary,
      height: "3rem",
    },
  },

  stageOuter: {
    height: "100%",
    color: colours.black,
    position: "relative",
    overflow: "hidden",
    maxHeight: "100%",
  },
  stageOuterLayout: {
    paddingBottom: FOOTER_HEIGHT,
  },
  stageInner: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "100%",
    paddingTop: "2rem",

    [ScreenSizes.lgAndAbove]: {
      paddingTop: "2.375rem",
    },
  },
  contentOverflowing: {
    position: "absolute",
    bottom: FOOTER_HEIGHT,
    left: 0,
    right: 0,
    background:
      "linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%)",
    pointerEvents: "none",
    height: 10,
    opacity: ".1",
  },

  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: FOOTER_HEIGHT,
    zIndex: 2,
    backgroundColor: colours.white,
    padding: "0 1.25rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 1.75rem",
    },
  },
  footerColumnFirst: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  footerColumnCentral: {},
  footerColumnLast: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
};

const DESKTOP_SLIDE_PROPS = {
  styles: {
    childrenInner: {
      [ScreenSizes.lgAndAbove]: {
        maxWidth: "95%",
        maxHeight: "36rem",
        height: "100%",
        width: "51.5rem",
        overflowY: "hidden",
      },
    },
  },
};

const MOBILE_SLIDE_PROPS = {
  slideInFrom: "bottom",
  fullWidth: true,
  fullHeight: true,
  styles: {
    childrenInner: {
      borderRadius: 0,

      [ScreenSizes.mdAndAbove]: {
        maxWidth: "100%",
      },
    },
  },
};

const STAGES = ["landing", "podcasts", "creators", "users", "finished"];

const OnboardingModal = (props) => {
  const {
    onRequestClose,
    onboardingStep,
    hideDoLater,
    showStepNavigation,
    social_connect,
    showFollowStages,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const setPreventOnboarding = useLocalStorage(
    "podchaser:prevent_onboarding_trigger",
    null
  )[1];
  const [socialConnect, setSocialConnect] = useState(social_connect);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const history = useHistory();

  const [contentOverflowing, setContentOverflowing] = useState(false);
  const onlyShowOneStep = !showStepNavigation && !!onboardingStep;
  const passedStageIndex =
    showFollowStages || onboardingStep
      ? STAGES.indexOf(showFollowStages ? "podcasts" : onboardingStep)
      : 0;
  const [stage, setStage] = useState(STAGES[passedStageIndex]);
  const stageIndex = STAGES.indexOf(stage);
  const showStageLayout =
    !onlyShowOneStep && stageIndex > 0 && stageIndex < STAGES.length - 1;

  const authUser = useLoggedInUser();
  const wasntFollowingAnythingRef = useRef(
    authUser && authUser.get("follow_count") === 0
  );
  const { updateProfile } = useActionCreators({
    updateProfile: updateProfileRoutine,
  });
  const {
    actions: { loadTimeline },
    timelineModuleLoaded,
  } = useTimelineAction();

  const outerRef = useRef(null);
  const innerRef = useRef(null);
  const [innerHeight, setInnerHeight] = useState(null);
  const outerHeight = outerRef.current && outerRef.current.clientHeight;

  const scrollToTop = () => {
    if (innerRef.current) {
      innerRef.current.scrollTop = 0;
    }
  };

  useLayoutEffect(() => {
    scrollToTop();
  }, []);

  useLayoutEffect(() => {
    scrollToTop();
  }, [stageIndex]);

  useLayoutEffect(() => {
    const newContentOverflowing = innerHeight > outerHeight;

    if (newContentOverflowing !== contentOverflowing) {
      setContentOverflowing(newContentOverflowing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerHeight, outerHeight]);

  useEffect(() => {
    const clearPreventOnboarding = () => {
      setPreventOnboarding(null);
      window.removeEventListener("beforeunload", clearPreventOnboarding);
    };

    window.addEventListener("beforeunload", clearPreventOnboarding);

    setPreventOnboarding(true);

    return clearPreventOnboarding;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // don't add triggers, should only trigger on mount and unmount

  useEffect(() => {
    sendGAEvent({
      action: "onboardingModalViewed",
    });
  }, []);

  const activityContext = useMemo(
    () => ({
      in_onboarding: true,
      onboarding_stage: stage,
    }),
    [stage]
  );

  const handleSetInnerHeight = useCallback(
    ({ height }) => setInnerHeight(height),
    []
  );

  const handleModalContentClick = useCallback((e) => e.stopPropagation(), []);

  const handleCloseClick = useCallback(() => {
    onRequestClose();
    updateProfile({
      meta_data: {
        frontend_flags: {
          "dismissed:onboarding_modal": true,
        },
      },
    });
    if (wasntFollowingAnythingRef.current && timelineModuleLoaded) {
      // only take them to the timeline if they've got here through registration
      loadTimeline({
        timelineKey: `timeline_aggregated:${authUser.get("id")}`,
      });
      history.push({ pathname: "/myfeed" });
    }
  }, [
    onRequestClose,
    updateProfile,
    loadTimeline,
    authUser,
    history,
    timelineModuleLoaded,
  ]);

  const handleNextStageClick = useCallback(
    (modalProps) => () => {
      const currentStageIndex = STAGES.indexOf(stage);
      const nextStageIndex = currentStageIndex + 1;

      setSocialConnect(null); // clear on stage changes

      if (nextStageIndex >= STAGES.length) {
        updateProfile({
          meta_data: {
            frontend_flags: {
              "completed:onboarding_modal": true,
            },
          },
        });
        modalProps.onClose();
      } else {
        const nextStage = STAGES[nextStageIndex];
        sendGAEvent({
          action: "onboardingModalStepViewed",
          step: nextStage,
        });
        setStage(nextStage);
      }
    },
    [updateProfile, stage, setStage]
  );

  const handlePreviousStageClick = useCallback(() => {
    const currentStageIndex = STAGES.indexOf(stage);
    const previousStageIndex = currentStageIndex - 1;

    setSocialConnect(null); // clear on stage changes

    if (previousStageIndex >= 0) {
      const previousStage = STAGES[previousStageIndex];

      setStage(previousStage);
    }
  }, [stage]);

  const renderStage = (modalProps) => {
    const stageProps = {
      stages: STAGES,
      stage,
      stageIndex,
      onClose: modalProps.onClose,
      nextStage: handleNextStageClick(modalProps),
      onStageComplete: handleNextStageClick(modalProps), // just encase continue clicks should be handled differently to skips
      previousStage: handlePreviousStageClick,
      mobile,
      onlyShowOneStep,
      hideDoLater,
      socialConnect,
      setSocialConnect,
      showFollowStages,
    };

    switch (stage) {
      case "finished":
        return <FinishedStage {...stageProps} />;
      case "creators":
        return <FollowCreatorStage {...stageProps} />;
      case "podcasts":
        return <FollowPodcastStage {...stageProps} />;
      case "users":
        return <FollowUserStage {...stageProps} />;
      default:
        return <LandingStage {...stageProps} />;
    }
  };

  const renderFooter = (modalProps) => (
    <div className={css(styles.footer)}>
      <div className={css(styles.footerColumnFirst)}>
        {showFollowStages && stage === "podcasts" ? null : (
          <FollowNavigationButton onBack={handlePreviousStageClick} />
        )}
      </div>
      <div className={css(styles.footerColumnCentral)}>
        {!showFollowStages && (
          <StageProgress stages={STAGES} stageIndex={stageIndex} />
        )}
      </div>
      <div className={css(styles.footerColumnLast)}>
        {showFollowStages && stage === "users" ? null : (
          <FollowNavigationButton
            onStageComplete={handleNextStageClick(modalProps)}
            nextStage={handleNextStageClick(modalProps)}
          />
        )}
      </div>
    </div>
  );

  const renderContent = (modalProps) => (
    <ActivityContext.Provider value={activityContext}>
      <div
        className={css(styles.modalContainer)}
        {...accessibleClickProps(modalProps.onClose)}
        role="button"
      >
        {mobile && (
          <div className={css(styles.modalTopFiller)}>
            <div className={css(styles.modalTopFillerIcon)}>
              <PodchaserBrandLogo width={42} height={42} iconOnly />
            </div>
          </div>
        )}
        <div
          className={css(
            styles.modalContent,
            mobile && styles.mobileModalContent
          )}
          {...accessibleClickProps(handleModalContentClick)}
          role="button"
        >
          <ModalCloseButton
            onClose={modalProps.onClose}
            light={mobile}
            withScrollBar={contentOverflowing || showStageLayout}
          />
          <div
            className={css(
              styles.stageOuter,
              showStageLayout && styles.stageOuterLayout
            )}
            ref={outerRef}
          >
            <div ref={innerRef} className={css(styles.stageInner)}>
              <OnResize onResize={handleSetInnerHeight}>
                {renderStage(modalProps)}
                {showStageLayout && contentOverflowing && (
                  <div className={css(styles.contentOverflowing)} />
                )}
                {showStageLayout && renderFooter(modalProps)}
              </OnResize>
            </div>
          </div>
        </div>
      </div>
    </ActivityContext.Provider>
  );

  return (
    <BaseModal
      {...props}
      onRequestClose={handleCloseClick}
      renderContent={renderContent}
      slideProps={mobile ? MOBILE_SLIDE_PROPS : DESKTOP_SLIDE_PROPS}
    />
  );
};

OnboardingModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  fullHeight: PropTypes.bool,
  onboardingStep: PropTypes.string,
  hideDoLater: PropTypes.bool,
  showStepNavigation: PropTypes.bool,
  social_connect: PropTypes.string,
  showFollowStages: PropTypes.bool,
};

OnboardingModal.defaultProps = {
  fullHeight: false,
  onboardingStep: null,
  hideDoLater: false,
  showStepNavigation: false,
  social_connect: null,
  showFollowStages: false,
};

export default OnboardingModal;
