import { css } from "aphrodite";
import range from "lodash/range";
import PropTypes from "prop-types";
import { memo } from "react";

import StandardButton from "components/Buttons/StandardButton";

import PodchaserHouseImage from "./podchaser-onboarding-podcast-house.svg";

import getUserDisplayName from "utils/entity/getUserDisplayName";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const smokeKeyframes = {
  "0%": {
    transform: "translateX(0)",
  },
  "25%": {
    transform: "translateX(-1.5px)",
  },
  "50%": {
    transform: "translateX(0)",
  },
  "75%": {
    transform: "translateX(1.5px)",
  },
  "100%": {
    transform: "translateX(0)",
  },
};

const smokeInnerKeyframes = {
  "0%": {
    transform: "translateY(0) scale(0.5)",
    opacity: 1,
  },
  "30%": {
    opacity: 0.8,
  },
  "90%": {
    opacity: 0.5,
  },
  "100%": {
    transform: "translateY(-60px) scale(1.6)",
    opacity: 0,
  },
};

const animationBase = {
  animationIterationCount: "infinite",
  transformBox: "fill-box",
  transformOrigin: "center bottom",
  animationTimingFunction: "ease-in-out",
};

const baseStyles = {
  landingStage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    textAlign: "center",
    margin: "0 1.875rem",

    [ScreenSizes.lgAndAbove]: {
      margin: "0 2rem 1.75rem",
    },
  },
  landingImageContainer: {
    position: "relative",
    display: "block",
    width: 274,
    height: 161,
    margin: "0 auto 1rem",
  },
  landingImage: {
    position: "relative",
  },
  smokeContainer: {
    top: 0,
    position: "absolute",
    display: "block",
    width: "100%",
    height: "100%",
  },
  smoke: {
    display: "block",
    position: "absolute",
    left: "55.5%",
    top: "13%",
    width: 10,
    height: 10,

    ...animationBase,
    animationName: [smokeKeyframes],
    animationDuration: "7s",
  },
  smokeInner: {
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: "2em",
    background: "rgb(220,220,220)",

    ...animationBase,
    animationName: [smokeInnerKeyframes],
    animationDuration: "7s",
  },
  landingContent: {
    color: colours.black,
  },
  landingTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.75rem",
    marginBottom: "1.75rem",
  },
  landingTitleIntro: {},
  landingTitleName: {
    wordBreak: "break-word",
  },
  landingThanksMessage: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    lineHeight: "29px",
    letterSpacing: "0.04px",
    maxWidth: "34.375rem",
    margin: "0 auto 1.75rem",
  },
  landingDescription: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    lineHeight: "29px",
    letterSpacing: "0.04px",
    maxWidth: "34.375rem",
    margin: "0 auto",
  },
  landingStartButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "2.5rem auto 1rem",
    maxWidth: "12rem",

    [ScreenSizes.lgAndAbove]: {
      margin: "2.875rem auto 1rem",
      maxWidth: "11rem",
    },
  },
  landingLaterButton: {
    ...gStyles.fontSemiBold,
    fontSize: ".813rem",
    color: "var(--color-neutral-d6)",
    cursor: "pointer",
    padding: "1rem 0",
  },
};

const smokeAmount = 8;
const duration = 8;
const stepSize = duration / smokeAmount;

const smokeStyles = range(smokeAmount).reduce((styles, index) => {
  const newStyles = { ...styles };
  // const step = index; //Math.floor(Math.random() * smokeAmount);

  // Horizontal
  newStyles[`smoke${index}`] = {
    animationDuration: `${6 + Math.random() * 2}s`, // 6 to 8
  };
  // Vertical & Opacity
  newStyles[`smokeInner${index}`] = {
    animationDelay: `${stepSize * index - 0.5 + Math.random() * 1}s`, // Current step +/- 1
    // animationDuration: `${7 + Math.random() * 2}s`, // 7 to 9
  };

  return newStyles;
}, {});

const LandingStage = (props) => {
  const { onClose, nextStage, hideDoLater } = props;
  const { styles } = useStyles([baseStyles, smokeStyles], props);

  const user = useLoggedInUser();

  const isNewUser = !user.get("email_verified"); // TODO: Better way to tell

  return (
    <div className={css(styles.landingStage)}>
      <div className={css(styles.landingImageContainer)}>
        <span className={css(styles.smokeContainer)}>
          {range(smokeAmount).map((index) => (
            <span
              key={index}
              className={css(styles.smoke, styles[`smoke${index}`])}
            >
              <span
                className={css(styles.smokeInner, styles[`smokeInner${index}`])}
              />
            </span>
          ))}
        </span>
        <img
          className={css(styles.landingImage)}
          src={PodchaserHouseImage}
          alt="Podchaser House"
          width="274"
          height="161"
        />
      </div>
      <div className={css(styles.landingContent)}>
        <div className={css(styles.landingTitle)}>
          <span className={css(styles.landingTitleIntro)}>Welcome home,</span>{" "}
          <span className={css(styles.landingTitleName)}>{`${getUserDisplayName(
            user,
            true
          )}!`}</span>
        </div>
        {isNewUser ? (
          <p className={css(styles.landingThanksMessage)}>
            Thanks for joining Podchaser, your new podcast discovery homepage.
          </p>
        ) : (
          <p className={css(styles.landingThanksMessage)}>
            {
              "We hope you're enjoying Podchaser, your podcast discovery homepage."
            }
          </p>
        )}
        <p className={css(styles.landingDescription)}>
          {
            "To make the most of our extensive range of features and to personalize your experience, let's start following some stuff."
          }
        </p>
        <div className={css(styles.landingStartButton)}>
          <StandardButton
            label="Get Started"
            variation="pink"
            onClick={nextStage}
          />
        </div>
        {!hideDoLater && (
          <div className={css(styles.landingLaterButton)} onClick={onClose}>
            {"I'll do this later"}
          </div>
        )}
      </div>
    </div>
  );
};

LandingStage.propTypes = {
  onClose: PropTypes.func.isRequired,
  nextStage: PropTypes.func.isRequired,
  hideDoLater: PropTypes.bool,
};

LandingStage.defaultProps = {
  hideDoLater: false,
};

export default memo(LandingStage);
