import { css } from "aphrodite";
import PropTypes from "prop-types";
import { Fragment, useState, useCallback, useMemo } from "react";

import InfiniteList from "components/Common/Lists/InfiniteList";

import FollowStageBaseSuggestionsSearchRow from "./FollowStageBaseSuggestionsSearchRow";
import FollowStageBaseSuggestionsSubTitle from "./FollowStageBaseSuggestionsSubTitle";
import FollowStageLoading from "./FollowStageLoading";

import paginationActions from "actions/pagination";
import { SORT_ORDER_RELEVANCE } from "constants/sort";
import { selectSocialConnectSyncDetails } from "selectors/auth";
import isEmpty from "utils/isEmpty";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import useLoadListEffect from "hooks/useLoadListEffect";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  suggestionsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: "1rem",
    width: "100%",
  },
};

const FollowStageBaseSuggestions = (props) => {
  const {
    socialConnect,
    suggestionsTitle,
    entity_type,
    isConnected,
    socialConnectFromTab,
    listKey,
    defaultTab,
    currentTabKey,
    suggestionsListConfig,
    connecting,
    infiniteListStyles,
    renderSuggestionItem,
  } = props;
  const { styles } = useStyles(baseStyles);

  const [search_term, setStateSearchTerm] = useState("");

  const socialConnectSyncDetails = useReduxState(
    (state) =>
      selectSocialConnectSyncDetails(state, socialConnect, entity_type),
    [socialConnect, entity_type]
  );

  const socialHasMatches =
    isConnected &&
    socialConnectSyncDetails &&
    socialConnectSyncDetails.total_matches > 0;

  const listSocialConnect = socialHasMatches ? currentTabKey : defaultTab;

  const listFilters = useMemo(() => {
    if (socialHasMatches) {
      return {
        filters: {
          by_social_network: { value: listSocialConnect },
        },
      };
    }

    return {};
  }, [listSocialConnect, socialHasMatches]);

  useLoadListEffect(listKey, {
    ...suggestionsListConfig,
    ...listFilters,
  });

  const { loading: pulledLoading, ids: items } = useList(listKey);
  const loading = pulledLoading || connecting;

  const { nextPage, setSearchTerm, applyConfig } = useActionCreators({
    nextPage: paginationActions.nextPage,
    setSearchTerm: paginationActions.setSearchTerm,
    applyConfig: paginationActions.applyConfig,
  });

  const handleLoadMore = useCallback(() => {
    if (!loading) {
      nextPage(listKey);
    }
  }, [loading, nextPage, listKey]);

  const resetListConfig = useCallback(
    () =>
      applyConfig({
        key: listKey,
        ...suggestionsListConfig,
        ...listFilters,
      }),
    [applyConfig, listFilters, listKey, suggestionsListConfig]
  );

  const handleSearchChange = useCallback(
    (newSearchTerm) => {
      setStateSearchTerm(newSearchTerm);

      if (isEmpty(search_term) && !isEmpty(newSearchTerm)) {
        // transition to term search
        applyConfig({
          key: listKey,
          options: {},
          filters: {},
          sort: SORT_ORDER_RELEVANCE,
          ...listFilters,
        });
      } else if (isEmpty(newSearchTerm)) {
        resetListConfig();
      }

      // this will trigger the actual search
      setSearchTerm(listKey, newSearchTerm);
    },
    [
      applyConfig,
      listFilters,
      listKey,
      resetListConfig,
      search_term,
      setSearchTerm,
    ]
  );

  const handleSearchClose = useCallback(() => {
    setStateSearchTerm("");
    setSearchTerm(listKey, "");
    resetListConfig();
  }, [setSearchTerm, listKey, resetListConfig]);

  return (
    <Fragment>
      <FollowStageBaseSuggestionsSubTitle
        search_term={search_term}
        socialConnect={socialConnect}
        suggestionsTitle={suggestionsTitle}
        entity_type={entity_type}
        isConnected={isConnected}
        socialConnectFromTab={socialConnectFromTab}
      />
      <FollowStageBaseSuggestionsSearchRow
        socialConnect={socialConnect}
        entity_type={entity_type}
        loading={loading}
        search_term={search_term}
        listKey={listKey}
        items={items}
        handleSearchChange={handleSearchChange}
        handleSearchClose={handleSearchClose}
      />
      <div>
        <div
          className={css(styles.suggestionsContainer)}
          data-id="follow-stage-base-suggestions"
        >
          {(!socialConnect || !connecting) && items && items.size > 0 && (
            <InfiniteList
              items={items}
              renderItem={renderSuggestionItem}
              onLoadMore={handleLoadMore}
              styles={infiniteListStyles}
            />
          )}
          {!loading && search_term && (!items || items.size === 0) && (
            <div className={css(styles.noResultsMessage)}>
              No results found.
            </div>
          )}
        </div>
        {loading && <FollowStageLoading />}
      </div>
    </Fragment>
  );
};

FollowStageBaseSuggestions.propTypes = {
  socialConnect: PropTypes.string,
  suggestionsTitle: PropTypes.node,
  entity_type: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  socialConnectFromTab: PropTypes.bool,
  listKey: PropTypes.string.isRequired,
  suggestionsListConfig: PropTypes.object.isRequired,
  defaultTab: PropTypes.string.isRequired,
  currentTabKey: PropTypes.string.isRequired,
  renderSuggestionItem: PropTypes.func.isRequired,
  connecting: PropTypes.bool,
  infiniteListStyles: PropTypes.object.isRequired,
};

FollowStageBaseSuggestions.defaultProps = {
  socialConnect: null,
  infiniteListStyles: null,
  suggestionsTitle: null,
  connecting: false,
};

export default FollowStageBaseSuggestions;
