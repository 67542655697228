import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";

import StandardButton from "components/Buttons/StandardButton";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  followContinueButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonContainer: {
    width: "5.75rem",
  },
  requiredCount: {
    ...gStyles.fontRegular,
    color: colours.black,
    fontSize: ".75rem",
    letterSpacing: "0.03px",
    marginRight: "1rem",
  },
  bold: {
    ...gStyles.fontSemiBold,
  },
};

const buttonStyles = {
  button: {
    paddingTop: ".45rem",
    paddingBottom: ".4rem",
  },
};

const FollowNavigationButton = (props) => {
  const {
    requiredSuggestionsCount,
    followCount,
    onStageComplete,
    onBack,
    nextStage,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const hasEnoughSuggestionsSelected = followCount >= requiredSuggestionsCount;

  const renderContinue = () => (
    <div className={css(styles.buttonContainer)}>
      <StandardButton
        label="Continue"
        variation="pink"
        fontSize=".75rem"
        onClick={onStageComplete}
        customStyles={buttonStyles}
      />
    </div>
  );

  const renderSkip = () => (
    <Fragment>
      <div className={css(styles.requiredCount)}>
        Choose at least{" "}
        <span className={css(styles.bold)}>
          {requiredSuggestionsCount - followCount}
        </span>{" "}
        {followCount > 0 && "more"}
      </div>
      {nextStage && (
        <div className={css(styles.buttonContainer)}>
          <StandardButton
            label="Skip"
            variation="subtle"
            fontSize=".75rem"
            onClick={nextStage}
            customStyles={buttonStyles}
          />
        </div>
      )}
    </Fragment>
  );

  if (onBack) {
    return (
      <div className={css(styles.buttonContainer)}>
        <StandardButton
          label="Go Back"
          fontSize=".75rem"
          variation="transparent"
          onClick={onBack}
          customStyles={buttonStyles}
        />
      </div>
    );
  }

  return (
    <div className={css(styles.followContinueButton)}>
      {hasEnoughSuggestionsSelected ? renderContinue() : renderSkip()}
    </div>
  );
};

FollowNavigationButton.propTypes = {
  nextStage: PropTypes.func,
  onStageComplete: PropTypes.func,
  onBack: PropTypes.func,
  followCount: PropTypes.number,
  requiredSuggestionsCount: PropTypes.number,
};

FollowNavigationButton.defaultProps = {
  onStageComplete: null,
  onBack: null,
  followCount: 0,
  requiredSuggestionsCount: 0,
};

export default memo(FollowNavigationButton);
