import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import StandardButton from "components/Buttons/StandardButton";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  finishedStage: {
    display: "flex",
    flexDirection: "column",
    color: colours.black,
    textAlign: "center",
    padding: "0 1.5rem",
    [ScreenSizes.lgAndAbove]: {
      padding: "4rem 0 0",
    },
  },
  finishStageIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: ".75rem",
    width: "100%",
    height: "4.125rem",
    fontSize: "4.125rem",
  },
  finishStageIcon: {
    color: colours.positive,
  },
  finishStageTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.75rem",
    marginTop: "1.5rem",
    marginBottom: "2rem",
  },
  finishStageDescription: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    lineHeight: "29px",
    letterSpacing: "0.04px",
    maxWidth: "34.375rem",
    margin: "0 auto 2rem",
  },
  finishStageDescriptionLink: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
  },
  finishStageDescriptionBold: {
    ...gStyles.fontSemiBold,
  },
  buttonContainer: {
    width: "12rem",
    margin: "0 auto 1rem",
  },
};

const FinishedStage = (props) => {
  const { nextStage, previousStage } = props;
  const { styles } = useStyles(baseStyles, props);

  const nextStageAndPreventDefault = (event) => {
    event.preventDefault();
    nextStage();
    return false;
  };

  return (
    <div className={css(styles.finishedStage)}>
      <div className={css(styles.finishStageIconContainer)}>
        <FontAwesomeIcon
          icon={faCheck}
          className={css(styles.finishStageIcon)}
        />
      </div>
      <div className={css(styles.finishStageTitle)}>
        {"Boom! You're all set."}
      </div>
      <div className={css(styles.finishStageDescription)}>
        Check back daily to see personalized recommendations, podcast reviews,
        Lists, and more podcast content in your{" "}
        <a
          href="/myfeed"
          className={css(styles.finishStageDescriptionLink)}
          onClick={nextStageAndPreventDefault}
        >
          Personal Feed
        </a>
        .
      </div>
      <div className={css(styles.buttonContainer)}>
        <StandardButton
          label="Start Using Podchaser"
          variation="pink"
          onClick={nextStage}
        />
      </div>
      <div className={css(styles.buttonContainer)}>
        <StandardButton
          label="Go Back"
          variation="transparent"
          onClick={previousStage}
        />
      </div>
    </div>
  );
};

FinishedStage.propTypes = {
  previousStage: PropTypes.func.isRequired,
  nextStage: PropTypes.func.isRequired,
};

export default memo(FinishedStage);
