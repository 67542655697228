import { css } from "aphrodite";
import PropTypes from "prop-types";
import qs from "qs";
import { memo, useCallback } from "react";

import EntityFollowStageBase from "components/Entities/Page/FollowStageBase";
import FollowStageBaseSuggestions from "components/Entities/Page/FollowStageBaseSuggestions";

import StageSelect from "./StageSelect";

import useQuery from "hooks/useQuery";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  followStageBase: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1.25rem 1rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 1.75rem 1rem",
    },
  },
  followStageDescription: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    lineHeight: "24px",
    letterSpacing: "0.04px",
    maxWidth: "41.5rem",
    width: "100%",
    padding: 0,
    margin: "0 auto 1.625rem",
    textAlign: "center",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 2rem",
      margin: "0 auto 1.5rem",
    },
  },
};

const baseStageStyles = {
  followControls: {
    [ScreenSizes.mdAndAbove]: {
      position: "relative",
      margin: "0 auto 2.5rem",
    },
  },
  importTitle: {
    display: "none",
  },
};

const FollowStageBase = (props) => {
  const {
    description,
    suggestionsTitle,
    searchPlaceholder,
    suggestionsListKey,
    renderSuggestionItem,
    suggestionsListConfig,
    mobile,
    infiniteListStyles,
    allowOPML,
    hideSocialImports,
    entity_type,
    stacked,
    stage,
    socialButtons,
    setSocialConnect,
    socialConnect,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const [query] = useQuery();

  const renderSuggestionsComponent = useCallback(
    (passedProps) => {
      const {
        isConnected,
        socialConnectFromTab,
        listKey,
        defaultTab,
        currentTabKey,
        connecting,
      } = passedProps;

      return (
        <FollowStageBaseSuggestions
          socialConnect={socialConnect}
          suggestionsTitle={suggestionsTitle}
          entity_type={entity_type}
          isConnected={isConnected}
          socialConnectFromTab={socialConnectFromTab}
          suggestionsListConfig={suggestionsListConfig}
          defaultTab={defaultTab}
          listKey={listKey}
          currentTabKey={currentTabKey}
          renderSuggestionItem={renderSuggestionItem}
          infiniteListStyles={infiniteListStyles}
          connecting={connecting}
        />
      );
    },
    [
      entity_type,
      infiniteListStyles,
      renderSuggestionItem,
      socialConnect,
      suggestionsListConfig,
      suggestionsTitle,
    ]
  );

  return (
    <div className={css(styles.followStageBase)}>
      <StageSelect {...props} />
      <div className={css(styles.followStageDescription)}>{description}</div>
      <EntityFollowStageBase
        socialButtons={socialButtons}
        listKey={suggestionsListKey}
        allowOPML={allowOPML}
        hideSocialImports={hideSocialImports}
        entity_type={entity_type}
        stacked={stacked}
        setSocialConnect={setSocialConnect}
        socialConnect={socialConnect}
        placeholder={searchPlaceholder}
        mobile={mobile}
        styles={baseStageStyles}
        getConnectReturnUrl={(socialType) =>
          `${window.location.pathname}?${qs.stringify({
            ...query,
            modal: "onboarding",
            modalprop_social_connect: socialType,
            modalprop_onboardingStep: stage,
            modalprop_showStepNavigation: true,
          })}`
        }
        renderSuggestionsComponent={renderSuggestionsComponent}
      />
    </div>
  );
};

FollowStageBase.propTypes = {
  description: PropTypes.node.isRequired,
  stage: PropTypes.string.isRequired,
  suggestionsTitle: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  searchPlaceholder: PropTypes.node,
  suggestionsListKey: PropTypes.string.isRequired,
  renderSuggestionItem: PropTypes.func.isRequired,
  suggestionsListConfig: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  infiniteListStyles: PropTypes.object,
  allowOPML: PropTypes.bool,
  entity_type: PropTypes.string.isRequired,
  hideSocialImports: PropTypes.bool,
  stacked: PropTypes.bool,
  socialButtons: PropTypes.array,
  socialConnect: PropTypes.string,
  setSocialConnect: PropTypes.func.isRequired,
};

FollowStageBase.defaultProps = {
  loading: false,
  searchPlaceholder: null,
  mobile: false,
  infiniteListStyles: null,
  allowOPML: false,
  hideSocialImports: false,
  socialButtons: null,
  socialConnect: null,
};

export default memo(FollowStageBase);
