import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import PodcastCard from "components/Entities/Cards/PodcastCards/PodcastCardContainerAsync";

import FollowStageBase from "./FollowStageBase";

import * as sortConstants from "constants/sort";
import loadPodcastsList from "sagas/pagination/lists/loadPodcastsList";

import useLoggedInUser from "hooks/useLoggedInUser";

import ScreenSizes from "styles/ScreenSizes";

const LIST_KEY = "onboarding/follow/podcasts";

const LIST_CONFIG = {
  sort: sortConstants.SORT_ORDER_CURATED,
  list_type: "podcasts_filtered",
  loadListAction: loadPodcastsList,
  entity_type: "podcast",
  pageSize: 24,
  filters: { in_curated_list: { value: "onboarding_podcasts" } },
};

const DESCRIPTION =
  "Follow a few of your favorite podcasts to see new releases, recent reviews, " +
  "recommendations, and more! You can also import your subscriptions from any podcast app later.";

const MOBILE_DESCRIPTION =
  "Follow a few of your favorite podcasts to see new releases, recent reviews, recommendations, and more!";

const itemsPerRow = {
  large: 8,
  medium: 4,
  small: 3,
  tiny: 2,
};

const getItemWidth = (perRow) => `${(100 / perRow).toFixed(2)}%`;

const infiniteListStyles = {
  sensorItem: {
    width: getItemWidth(itemsPerRow.tiny),

    [ScreenSizes.smAndAbove]: {
      width: getItemWidth(itemsPerRow.small),
    },
    [ScreenSizes.mdAndAbove]: {
      width: getItemWidth(itemsPerRow.medium),
    },
    [ScreenSizes.lgAndAbove]: {
      width: getItemWidth(itemsPerRow.large),
    },
  },
};

const FollowPodcastStage = (props) => {
  const { mobile } = props;
  const loggedInUser = useLoggedInUser();

  const renderItem = useCallback(
    (podcast) => (
      <PodcastCard
        key={podcast}
        entity_id={podcast}
        layout="follow"
        externalLink
      />
    ),
    []
  );

  return (
    <FollowStageBase
      {...props}
      title="Follow Podcasts"
      description={mobile ? MOBILE_DESCRIPTION : DESCRIPTION}
      suggestionsListKey={LIST_KEY}
      suggestionsTitle={
        mobile
          ? "Some popular podcasts to follow"
          : "Here are a few popular podcasts to get you started."
      }
      listKey={LIST_KEY}
      suggestionsListConfig={LIST_CONFIG}
      renderSuggestionItem={renderItem}
      requiredSuggestionsCount={3}
      followCount={loggedInUser.get("podcast_follow_count")}
      infiniteListStyles={infiniteListStyles}
      entity_type="podcast"
    />
  );
};

FollowPodcastStage.propTypes = {
  mobile: PropTypes.bool,
};

FollowPodcastStage.defaultProps = {
  mobile: false,
};

export default memo(FollowPodcastStage);
